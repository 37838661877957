import React, { FC } from 'react';
import { MainContent } from '../../layout/main-content';
import { siteData } from '../../../data/site-data';

export const SolutionsTradingHero: FC = () => {
  /**
   * DOM
   */
  return (
    <div className="flex justify-center">
      <MainContent width="max-w-3xl">
        <div className="flex flex-col items-center py-20">
          <p
            className={
              'ff-secondary text-center text-primary whitespace-pre-line typo-h2'
            }
          >
            {siteData.solutionsPage.trading.hero.title}
          </p>
          <p className="text-grey-darker typo-b2 my-6 text-center">
            {siteData.solutionsPage.trading.hero.description}
          </p>
        </div>
      </MainContent>
    </div>
  );
};
