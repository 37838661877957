import React, { FC } from 'react';
import {
  SolutionsTradingHero,
  SolutionsTradingFeatures,
} from '../../components/solutions/trading';
import { PageLayout } from '../../components/layout';
import { SolutionsMakeAnAppointment } from '../../components/solutions';

const Page: FC = () => {
  /**
   * DOM
   */
  return (
    <PageLayout bgCls="bg-accent-light">
      <SolutionsTradingHero />
      <SolutionsTradingFeatures />
      <SolutionsMakeAnAppointment cls="light" icon="email-checked" />
    </PageLayout>
  );
};

export default Page;
