import React, { FC } from 'react';
import Fade from 'react-reveal/Fade';
import { MainContent } from '../../layout/main-content';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { siteData } from '../../../data/site-data';
import {
  SectionImage,
  SectionImageContentComponent,
  SectionImageType,
  SectionTextContent,
} from '../../shared';

export const SolutionsTradingFeatures: FC = () => {
  /**
   * Hooks
   */
  const breakpoints = useBreakpoint();

  /**
   * DOM
   */
  const isMobile = breakpoints.xs || breakpoints.sm || breakpoints.md ? true : false;
  return (
    <div className="bg-primary py-6">
      <MainContent>
        {siteData.solutionsPage.trading.sectionFeatures.map((item, index) => {
          return (
            <Fade>
              <SectionImageContentComponent
                direction={index % 2 === 0 || isMobile ? 'ltr' : 'rtl'}
                cls="py-6 md:py-12"
                fgImage={
                  <SectionImage
                    name={item.imageName as SectionImageType}
                    alt={item.title}
                  />
                }
                content={
                  <SectionTextContent
                    cls="items-center lg:mb-12"
                    title={item.title}
                    titleCls={'ff-secondary text-center md:text-left text-white typo-h3'}
                    description={item.description}
                    descriptionCls={'text-gray-400 text-center md:text-left typo-b2'}
                  />
                }
              />
            </Fade>
          );
        })}
      </MainContent>
    </div>
  );
};
